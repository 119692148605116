import React from 'react'
import { Query } from '@apollo/client/react/components'
import get from 'lodash/get'
import dynamic from 'next/dynamic'

import withLayout from '~/hoc/withLayout'
import withFestivalContext from '~/hoc/withFestivalContext'

import Modal from '~/components/Modal'
import { commonModals } from '~/components/Modal/modalsList'

import { FESTIVAL_EDITION_STATES } from '~/utils/consts'
import { GET_CURRENT_FESTIVAL_EDITION } from '~/utils/queries.gql'

const IndexRW = dynamic(() => import('~/components/landing-rw/index'))
const IndexFDW = dynamic(() => import('~/components/landing-fdw/index'))
const IndexRWX = dynamic(() => import('~/components/landing-rwx/index'))
const IndexKORW = dynamic(() => import('~/components/landing-korw/index'))
const IndexBFW = dynamic(() => import('~/components/landing-bfw/index'))
const IndexDW = dynamic(() => import('~/components/landing-dw'))
const IndexCF = dynamic(() => import('~/components/landing-cf'))

export default
@withLayout({
  namespaces: ['tooltips', 'modal'],
  contentClassName: 'homepage'
})
@withFestivalContext
class Index extends React.Component {

  render () {
    const { festivalName } = this.props

    return (
      <>
        <Query
          query={GET_CURRENT_FESTIVAL_EDITION}
          variables={{ code: festivalName }}
          skip={!festivalName}
        >
          {({ data }) => {
            const isVoucher = get(data, 'currentFestivalEdition.state') === FESTIVAL_EDITION_STATES.VOUCHER

            switch (festivalName) {
              case 'fdw':
                return <IndexFDW isVoucher={isVoucher} />
              case 'rwx':
                return <IndexRWX isVoucher={isVoucher} />
              case 'korw':
                return <IndexKORW isVoucher={isVoucher} />
              case 'bfw':
                return <IndexBFW isVoucher={isVoucher} />
              case 'dw':
                return <IndexDW isVoucher={isVoucher} />
              case 'cf':
                return <IndexCF isVoucher={isVoucher} />
              default:
                return <IndexRW isVoucher={isVoucher} />
            }
          }}
        </Query>
        <Modal components={commonModals} />
      </>
    )
  }
}
